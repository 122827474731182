import { Button, Card, Modal, Space, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { type resultInitialState } from '../../../../hooks/constants';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EyeOutlined,
  AlignLeftOutlined,
  ApiOutlined
} from '@ant-design/icons';
import { Editor } from '@monaco-editor/react';

interface HistoryModalProps {
  history: Array<typeof resultInitialState>
  showHistory: boolean
  title: string
  selected: typeof resultInitialState
  handleHideHistory: () => void
  handleRollback: () => void
  handleSelectedIndex: (id: string) => void
}

export default function HistoryModal ({
  history,
  showHistory,
  title,
  selected,
  handleHideHistory,
  handleRollback,
  handleSelectedIndex
}: HistoryModalProps) {
  const { t: translation } = useTranslation();

  return (
    <Modal
      title={translation('history')}
      centered
      open={showHistory}
      onCancel={handleHideHistory}
      okText="Rollback last"
      onOk={handleRollback}
      width={'80vw'}
    >
      <div style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {
            history?.map((i) => (
              <Card
                key={`${i.id}-${i.timestamp}`}
                title={(
                  <Space>
                    { i.isUrl ? <ApiOutlined /> : <AlignLeftOutlined /> }
                    { title }
                    { i.isValid ? <CheckCircleTwoTone twoToneColor="#87d068" /> : <CloseCircleTwoTone twoToneColor="#f50" /> }
                    { selected?.id === i.id && (
                      <Tag color="#6E44FF">
                          selected
                      </Tag>
                    ) }
                  </Space>
                )}
                extra={(
                  <Button
                    icon={<EyeOutlined />}
                    type="link" onClick={() => { handleSelectedIndex(i.id); }}
                    disabled={!i.isValid}
                  >
                    { translation('select') }
                  </Button>
                )}
              >
                <div style={{ maxHeight: '120px', overflowY: 'scroll' }}>
                  { i.isValid
                    ? (
                      <Editor
                        defaultLanguage="json"
                        value={i.json}
                        height={120}
                      />
                  // eslint-disable-next-line indent
                      )
                    : (
                      <Typography.Text type="danger">
                        { i.error?.reason }
                      </Typography.Text>
                  // eslint-disable-next-line @typescript-eslint/indent
                    )
                  }
                </div>
              </Card>
            ))
          }
        </Space>
      </div>
    </Modal>
  );
}
