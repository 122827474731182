/* eslint-disable n/no-callback-literal */
import React, { useRef, useState, useEffect } from 'react';
import { message, notification } from 'antd';
import { CopyTwoTone } from '@ant-design/icons';
import useUtil, { parseValue } from '../../../hooks/useJsonUtil/useJsonUtil';
import { resultInitialState } from '../../../hooks/constants';
import { type CheckboxChangeEvent } from 'antd/es/checkbox';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { isValidUrl } from '../../../utils';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../App';
import { type SearchResultCallback } from './prettyJsonScreen.types';

export default function usePRettyJsonScreen () {
  const textArea = useRef<HTMLTextAreaElement>(null);

  const [showTextArea, setShowTextArea] = useState(false);
  const [value, setValue] = useState('');
  const [results, setResult] = useState<Array<typeof resultInitialState>>([]);
  const [selected, setSelected] = useState<typeof resultInitialState>({ ...resultInitialState });
  const [isLoading, setIsLoading] = useState(false);
  const [identation, setIdentation] = useState(4);
  const [autofix, setAutoFix] = useState(true);
  const [ignoreComments, setIgnoreComments] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [alias, setAlias] = useState('');
  const [isUrl, setIsUrl] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [minify, setMinify] = useState(false);
  const [body, setBody] = useState<unknown>({});
  const [invalidBody, setInvalidBody] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);

  const showEditor = Boolean(selected?.isValid && selected?.json && !isLoading);
  const showComparison = showTextArea && showEditor;
  const showRollback = Boolean(selected?.id && selected.id !== results[results.length - 1]?.id);
  const isTheLastSuccess = Boolean(selected?.id && selected.id === results[results.length - 1]?.id);
  const title = `${alias ? `${alias} → ` : ''}${dayjs(selected.timestamp).format('MMM D, h:mm:ss A')}`;
  const isObject = selected.json ? isArray(JSON.parse(selected.json)) : false;

  const [messageApi, messageHolder] = message.useMessage();
  const [notificationApi, notificationHolder] = notification.useNotification();
  const { t: translation } = useTranslation();
  const {
    convertAndValidateJson,
    jsonFileGenerator,
    requestData,
    isValidJson
  } = useUtil();

  useEffect(() => {
    if (selected?.json && selected?.isValid) toggleShowTextArea(false);
    else toggleShowTextArea(true);
  }, [selected?.isValid, selected?.json]);

  useEffect(() => {
    if (selected?.error.reason) {
      const start = selected.error.location.start.offset - 4;
      const end = selected.error.location.start.offset;
      textArea?.current?.setSelectionRange(start, end);
    }
  }, [selected?.error?.location.start.offset]);

  useEffect(() => {
    // eslint-disable-next-line curly
    if (isUrl) notificationApi.info({
      message: translation('urlDetected')
    });
  }, [isUrl]);

  useEffect(() => {
    if (selected.isMinify) {
      setMinify(true);
    } else if (minify) setMinify(false);
  }, [selected?.isMinify]);

  useEffect(() => {
    if (selected?.request?.method === 'POST') {
      setIsPost(true);
      if (selected?.request.body) setBody(selected.request.body);
    } else if (isPost) {
      setIsPost(false);
      setBody({});
    }
  }, [selected?.request?.method]);

  const handleText = ({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (isValidUrl(target?.value)) {
      setIsUrl(true);
    } else if (isUrl) setIsUrl(false);

    setValue(target?.value);
  };

  const toggleShowTextArea = (show: boolean) => { setShowTextArea(show); };

  const format = (minify?: boolean, data?: any, request?: any) => {
    if (data || value) {
      const tabSize = isNaN(identation) ? 4 : identation;
      const convertion = convertAndValidateJson(
        data || value,
        tabSize,
        {
          autofix,
          ignoreComments,
          minify,
          request
        }
      );
      if (convertion?.isValid) {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          setResult((prev) => [...prev, convertion]);
          setSelected(convertion);
        }, 1000);
      } else {
        setResult((prev) => [...prev, convertion]);
        setSelected(convertion);
      }
    }
  };

  const handleFormat = () => {
    logEvent(analytics, 'format');
    format(false);
  };

  const handleMinify = () => {
    logEvent(analytics, 'minify');
    format(true);
  };

  const handleTabSize = (size: number | null) => {
    logEvent(analytics, 'identation', { size });
    size && setIdentation(isNaN(size) ? 4 : size);
  };

  const handleAutofix = ({ target }: CheckboxChangeEvent) => { setAutoFix(target.checked); };

  const handleIgnoreComments = ({ target }: CheckboxChangeEvent) => { setIgnoreComments(target.checked); };

  const handleCopy = async (): Promise<void> => {
    logEvent(analytics, 'copied');
    await navigator.clipboard.writeText(selected.json);
    await messageApi.open({
      type: 'success',
      content: translation('copied'),
      icon: <CopyTwoTone twoToneColor="#87d068" />
    });
  };

  const handleDownload = () => {
    logEvent(analytics, 'download');
    jsonFileGenerator(minify ? selected.jsonMin : selected.json, alias, minify);
  };

  const handleShowHistory = () => {
    logEvent(analytics, 'showHistory');
    setShowHistory(true);
  };

  const handleHideHistory = () => { setShowHistory(false); };

  const handleAlias = ({ target }: React.ChangeEvent<HTMLInputElement>) => { setAlias(target.value); };

  const handleSelectedIndex = (id: string) => {
    setShowHistory(false);
    const selected = results.find((r) => r.id === id);

    if (selected?.id) {
      setSelected({ ...selected });
      setValue(selected.isUrl ? selected.request.url : selected.original || value);
    }
  };

  const handleRollback = () => {
    const lastSuccess = results.find((r) => r.isValid);
    handleSelectedIndex(lastSuccess?.id as string);
  };

  const handleNew = () => {
    setValue('');
    setShowTextArea(true);
    setSelected({ ...resultInitialState });
  };

  const handleClear = () => {
    setShowTextArea(true);
    setValue('');
    setResult([]);
    setSelected({ ...resultInitialState });
    setIdentation(4);
    setAutoFix(true);
    setIgnoreComments(false);
    setShowHistory(false);
    setAlias('');
  };

  const handleRequest = async () => {
    logEvent(analytics, 'request', { type: isPost ? 'POST' : 'GET' });
    const response = await requestData(value, isPost ? 'POST' : 'GET', body);

    if (response.isSuccess && response?.data) format(false, JSON.stringify(response.data), response);
    else {
      notificationApi.error({
        message: response.error.status,
        description: response.error.message
      });
    }
  };

  const tooglePost = () => { setIsPost(prev => !prev); };

  const toggleMinify = () => { setMinify(prev => !prev); };

  const handleBody = (value: string | undefined) => {
    const json = isValidJson(value);

    if (json.isValid) {
      setBody(json.json);
      if (invalidBody) setInvalidBody(false);
    } else setInvalidBody(true);
  };

  const toggleSearchModal = () => {
    if (!showSearchModal) logEvent(analytics, 'search');
    setShowSearchModal((prev) => !prev);
  };

  const getValueByKey = (dotNotation: string, callback?: (result: SearchResultCallback) => void) => {
    logEvent(analytics, 'getByKey');
    const currentJson = JSON.parse(selected.json);
    let result: unknown[] | object;

    if (isObject) {
      result = get(currentJson, dotNotation);
      return callback?.({ success: true, data: result });
    }

    result = map(currentJson, (v) => get(v, dotNotation));
    return callback?.({ success: true, data: result });
  };

  const getValuesByFilter = (condition: string, callback?: (result: SearchResultCallback) => void) => {
    const evaluator = condition.match(/==|===|!=|!==|<|>|<=|=>/)?.[0];
    if (!evaluator) return callback?.({ success: false, data: null });

    const currentJson = JSON.parse(selected.json);
    const conditional = condition.split(evaluator);
    const key = conditional[0];
    const value: string | number | boolean = parseValue(conditional[1]) as any;

    const data = filter(currentJson, (item) => {
      const got = parseValue(String(get(item, key)));

      if (!got) return false;
      /* eslint-disable */
      switch (evaluator) {
        case '==' || '===':
          return value === got;
        case '!=' || '!==':
          return value !== got;
        case '>':
          return value > got;
        case '<':
          return value < got;
        case '<=':
          return value <= got;
        case '>=':
          return value >= got;

        default:
          return false;
      }
      /* eslint-disable */
    });

    return callback?.({ success: true, data });
  };

  return {
    textArea,
    showTextArea,
    value,
    result: selected,
    results,
    isLoading,
    identation,
    autofix,
    ignoreComments,
    showEditor,
    showComparison,
    showRollback,
    isTheLastSuccess,
    showHistory,
    alias,
    title,
    messageHolder,
    notificationHolder,
    isUrl,
    isPost,
    minify,
    invalidBody,
    body,
    showSearchModal,
    isObject,
    handleText,
    toggleShowTextArea,
    handleFormat,
    handleMinify,
    handleTabSize,
    handleAutofix,
    handleIgnoreComments,
    handleCopy,
    handleDownload,
    handleShowHistory,
    handleHideHistory,
    handleAlias,
    handleSelectedIndex,
    handleRollback,
    handleNew,
    handleClear,
    handleRequest,
    tooglePost,
    toggleMinify,
    handleBody,
    toggleSearchModal,
    getValueByKey,
    getValuesByFilter
  };
}
