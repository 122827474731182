import styled from 'styled-components';

export const TextAreaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
`;

export const EditorContainer = styled.div`
  margin-top: 16px;
  padding: 8px 16px;
  background-color: white;
  border-radius: 9px;
  border: 1px #C8CDD0 solid;
  margin-bottom: 8px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 250px;
  border-radius: 9px;
  margin-top: 36px;
  padding: 8px 16px;
  border: 1px #C8CDD0 solid;
`;
