import { Divider, Input, Modal, Select, Space, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type SearchResultCallback, type SearchOptionType, type SearchResult } from '../prettyJsonScreen.types';
import { Editor } from '@monaco-editor/react';
import Markdown from 'react-markdown';

interface SearchModalProps {
  visible: boolean
  isObject: boolean
  onClose: () => void
  onSearchByKey: (value: string, cb: (reult: SearchResultCallback) => void) => void
  onSearchByFilter: (value: string, cb: (reult: SearchResultCallback) => void) => void
}

export default function SearchModal ({
  visible,
  isObject,
  onClose,
  onSearchByKey,
  onSearchByFilter
}: SearchModalProps) {
  const [searchType, setSearchType] = useState<SearchOptionType>('byKey');
  const [result, setResult] = useState<SearchResult | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');

  const { t: translation } = useTranslation();

  const reset = () => {
    setSearchType('byKey');
    setResult(undefined);
    setValue('');
  };

  return (
    <Modal
      title={translation('searchModal.title')}
      open={visible}
      width={'60vw'}
      onCancel={() => {
        onClose?.();
        reset();
      }}
      onOk={onClose}
      okButtonProps={{ disabled: true, style: { display: 'none' } }}
    >
      <Space direction='vertical' style={{ width: '100%' }}>
        <Input.Search
          style={{ width: 450 }}
          placeholder={translation(`searchModal.placeholder.${searchType}`)}
          loading={isLoading}
          autoFocus
          value={value}
          onChange={({ target }) => { setValue(target.value); } }
          addonBefore={(
            <Select
              defaultValue="byKey"
              onChange={(opt: SearchOptionType) => {
                setSearchType(opt);
                setValue('');
                setResult(undefined);
              }}
              value={searchType}
              style={{ minWidth: 110 }}
            >
              <Select.Option value="byKey">
                {translation('searchModal.searchByKey')}
              </Select.Option>
              <Select.Option value="byFilter" disabled={!isObject}>
                {translation(isObject ? 'searchModal.searchByFilter' : 'searchModal.searchByFilterDisabled')}
              </Select.Option>
            </Select>
          )}
          enterButton width={400}
          onSearch={(value) => {
            setIsLoading(true);
            if (searchType === 'byKey') {
              onSearchByKey(value, (result) => {
                setResult(result.data);
                setIsLoading(false);
              });
            } else {
              onSearchByFilter(value, (result) => {
                setResult(result.data);
                setIsLoading(false);
              });
            }
          }}
        />
        <Typography.Text type='secondary'>
          {translation(`searchModal.tooltip.${searchType}`)}
          {/* eslint-disable */}
          { searchType === 'byFilter'
            ? (
              <Space direction='horizontal'>
                <Markdown>
                  {'```==```'}
                </Markdown>
                <Markdown>
                  {'```!=```'}
                </Markdown>
                <Markdown>
                  {'```<```'}
                </Markdown>
                <Markdown>
                  {'```>```'}
                </Markdown>
                <Markdown>
                  {'```<=```'}
                </Markdown>
                <Markdown>
                  {'```>=```'}
                </Markdown>
              </Space>
            )
            : (
              <Markdown>
                {'```world.people[0].name```'}
              </Markdown>
            )
          }
        </Typography.Text>
      </Space>
      <Divider />
      {
        Boolean(result) && (
          <Editor
            defaultLanguage="json"
            value={JSON.stringify(result, null, 4)}
            height="400px"
          />
        )
      }
    </Modal>
  );
}
