import usePrettyJsonScreen from './usePrettyJsonScreen';
import styles from './PrettyJsonScreen.module.css';
import { LoadingOutlined } from '@ant-design/icons';
import InputControl from './components/InputControl';
import JsonEditor from './components/JsonEditor';
import HistoryModal from './components/HistoryModal';
import logo from '../../../assets/img/prettyjso_logo.png';
import SearchModal from './components/SearchModal';

function PrettyJsonScreen () {
  const {
    textArea,
    value,
    showTextArea,
    result,
    results,
    isLoading,
    identation,
    autofix,
    ignoreComments,
    showComparison,
    showEditor,
    showRollback,
    isTheLastSuccess,
    showHistory,
    alias,
    title,
    messageHolder,
    notificationHolder,
    isUrl,
    isPost,
    minify,
    invalidBody,
    body,
    showSearchModal,
    isObject,
    handleText,
    toggleShowTextArea,
    handleFormat,
    handleMinify,
    handleTabSize,
    handleAutofix,
    handleIgnoreComments,
    handleCopy,
    handleDownload,
    handleShowHistory,
    handleHideHistory,
    handleAlias,
    handleSelectedIndex,
    handleRollback,
    handleNew,
    handleClear,
    handleRequest,
    tooglePost,
    toggleMinify,
    handleBody,
    toggleSearchModal,
    getValueByKey,
    getValuesByFilter
  } = usePrettyJsonScreen();

  return (
    <>
      { messageHolder }
      { notificationHolder }
      <img src={logo} style={{ width: 150 }} />
      <InputControl
        visible={showTextArea && !isLoading}
        textArea={textArea}
        data={result}
        value={value}
        isUrl={isUrl}
        hiddeable={showComparison}
        ignoreTrailingCommas={autofix}
        ignoreComments={ignoreComments}
        tabSize={identation}
        alias={alias}
        isInvalidBody={invalidBody}
        defaultBody={JSON.stringify(body, null, 4)}
        onChange={handleText}
        onRequest={handleRequest}
        onFormat={handleFormat}
        onMinify={handleMinify}
        onShow={toggleShowTextArea}
        onChangeIgnoreTrailingCommas={handleAutofix}
        onChangeIgnoreComments={handleIgnoreComments}
        onChangeTabSize={handleTabSize}
        onChangeAlias={handleAlias}
        isPost={isPost}
        onChangePost={tooglePost}
        onChangeBody={handleBody}
      />
      <JsonEditor
        visible={showEditor}
        divisor={showComparison}
        json={result.json}
        rollback={showRollback}
        count={results.length === 1 ? 0 : results.length}
        title={title}
        isTheLastSuccess={isTheLastSuccess}
        data={result}
        compress={minify}
        onToggle={toggleShowTextArea}
        onNew={handleNew}
        onRollback={handleRollback}
        onHistory={handleShowHistory}
        onDownload={handleDownload}
        onCopy={handleCopy as () => void}
        onClean={handleClear}
        onMinify={toggleMinify}
        onSearch={toggleSearchModal}
      />
      {
        isLoading && (
          <LoadingOutlined className={styles.loading_icon} />
        )
      }
      <HistoryModal
        showHistory={showHistory}
        history={results?.reverse()}
        selected={result}
        title={title}
        handleHideHistory={handleHideHistory}
        handleRollback={handleRollback}
        handleSelectedIndex={handleSelectedIndex}
      />
      <SearchModal
        visible={showSearchModal}
        isObject={isObject}
        onClose={toggleSearchModal}
        onSearchByKey={getValueByKey}
        onSearchByFilter={getValuesByFilter}
      />
    </>
  );
}

export default PrettyJsonScreen;
